import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default function Me() {
    return (

        <motion.div className='lg:flex'
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                type: "spring",
                duration: 0.5,
            }}
        >
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                width="100%"
                id="blobSvg"
                style={{ opacity: 1 }}
                filter="blur(0px)"
            >
                <image
                    x={35}
                    y={60}
                    width="88%"
                    height="88%"
                    clipPath="url(#shape)"
                    href="https://i.postimg.cc/PfLj8VVJ/1000027469-Copy.jpg"
                    preserveAspectRatio="none"
                />
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style={{ stopColor: "rgb(238, 205, 163)" }} />
                        <stop offset="100%" style={{ stopColor: "rgb(239, 98, 159)" }} />
                    </linearGradient>
                </defs>
                <clipPath id="shape">
                    <path id="blob" fill="url(#gradient)">
                        <animate
                            attributeName="d"
                            dur="10000ms"
                            repeatCount="indefinite"
                            values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
                        />
                    </path>
                </clipPath>
            </svg>

            <div className='m-4 text-justify font-Inter flex flex-col content-center justify-center'>
                <h1 className=' text-lg font-thin font-Quicksand'>HELLOW,</h1>
                <p className='border-b-2 border-neutral-400 pb-1 mb-4 text-2xl font-bold'>I'M SUSMOY DEBNATH</p>
                <p className='font-Inter'>I am a student currently studying in in intermediate 1st year
                at Government Science College in Dhaka, Bangladesh. I am a young individual with a passion for programming and ethical hacking. I have a keen interest in understanding how computer systems and networks work, and is determined to pursue a career in the field of technology. With a strong foundation in programming, I am well-equipped to take on any challenges that come my way. I am a hard-working and dedicated student who always strives to do his best.</p>

                <p className='my-4 font-Inter'>In addition to my academic pursuits, I am also actively involved in various extracurricular activities. I have a strong desire to make a positive impact in the world through my work in technology. I am committed to using my skills and knowledge to make a difference in the lives of others and is driven to create innovative solutions to problems.</p>

                <div className='py-4 flex justify-center md:justify-start'>
                    <Link to="/Contact" className='px-8 py-2 bg-white hover:bg-transparent hover:border hover:border-white border-white border hover:text-white btn transition-all delay-100 rounded-full text-neutral-900 font-bold'>
                        Contact
                    </Link>
                    <Link to="/" className='mx-3 md:mx-4 px-8 py-2 bg-neutral-900 hover:bg-white border-white border hover:text-neutral-900 btn transition-all delay-100 rounded-full text-white font-bold'>
                        Download CV
                    </Link>
                </div>
            </div>
        </motion.div>

    )
}
